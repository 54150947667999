import React, { useEffect, useState } from "react";
import "../style.css";
import "./videolist_style.css"
import { useHistory } from "react-router-dom";
import { remove_Cookie } from "../../Cookies/remove_cookie";
import { getVideosFolder } from "../../Api/ishApi/ishapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface FolderProps {}

export const FolderList: React.FC<FolderProps> = () => {
  const [folder_names, setFolderName] = useState([]);
  const [type, setType] = useState("");

  const [error,setError] = useState(false)

  const history = useHistory();
  remove_Cookie('video_running')

  let sec_langauge:any = localStorage.getItem('Selected_langauge')
  const [langauge, setLangauge] = useState(sec_langauge)
  

  useEffect(() => {
    list_folder(langauge)
    localStorage.setItem('Selected_langauge',langauge)
    
  }, [langauge]);


  const list_folder = async (langauge:any) =>{
    let folder = await getVideosFolder(langauge)

    if(folder.err != true){
      setFolderName(folder)
    }else{
      setError(true)
    }
  }
 
  // const langauge_selection = async (id: any) => {
  //   if (id === undefined && sec_langauge === undefined) {
      
  //     setLangauge(localStorage.setItem('Selected_langauge','english'))
  //   } 
  //   else {
  //     setLangauge(sec_langauge)
  //   }
  // }
  
  
  const content = folder_names?.map((item: any, index: any) => (
    <div className="grid-row-folder">
      <div className="grid-item-folder" key={index} onClick={() => {history.push("videos/" + item); setType(item) }}>
          <img style={{height: '78%',width: '100%'}} src="../assets/ishIcons/creativity4.jpg"></img>
          <div style={{ width: '100%',fontSize: '1.25rem',padding: '2rem',height: '30%',lineHeight: '1.5rem',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>{item.split(".")[0]}</div>
      </div>
    </div>
  ));
  



  return (
      <>
        <div className="background_image">
              <div style={{display:'flex', justifyContent:'space-between',flexDirection: 'row'}}>
                  <div style={{fontWeight: '800',fontFamily: 'Nunito-Bold',fontSize: '1.8rem',padding:'2rem',color: 'black'}}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={() =>history.push('/ish')} style={{cursor:"pointer", marginTop:"0.8rem", marginRight:"0.8rem"}} />
                    
                    Playlist 
                    </div>
                    <div style={{display:'flex',padding: '1rem'}}>
                      <div className="select_language " >Langauge </div>
                        <select name="" id="" className="option_row langauge_option" value={langauge}
                          onChange={(e) => {
                            setLangauge(e.target.value)
                          }} >
                          <option value="english" selected>English</option>
                          <option value="bengali">Bengali</option>
                      </select>
                    </div>
              </div>
        
              <div className="book_list">
                {content.length > 0 ? content : <div style={{ padding: '1rem', display: 'flex' }}></div>}
              </div>
          </div>
      </>
  );
};

