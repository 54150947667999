import React from "react";
import { Modal } from "react-bootstrap";
import {useState } from "react";
import JsonData from "./booklist.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface BooksPageProps {
}

export const BooksPage: React.FC<BooksPageProps> = ({}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [modalData, setModalData] = useState<any>();
    const history = useHistory()

        const content_books = JsonData.map((item:any,index:any) =>{
            return (
                <> 
                <div className="grid-row-books">
                    <div className="grid-item-books">
                        <img style={{height: '100%',width: '40%'}} src = {item?.image_url}></img>
                        <div style={{padding:'0.5rem'}}>
                            <p style={{fontSize:'2rem',height: '30%'}}>{item.Bookname}</p>
                            <p style={{fontSize:'1rem',height: '20%'}}>Author : {item?.Author}</p>
                            <p style={{fontSize:'1rem'}}>{item.summary.slice(0,40)}..... <u style={{color:'#1890ff'}} onClick={() => {setModalData(item);setShow(true) }}>See more</u></p>
                            <button  className="buy_button" onClick={() =>window.open (item.url)}>Buy Book</button>
                        </div>
                    </div>
                </div>
                {item === modalData?
                    <Modal show={show} className="booklist_modal" onHide={handleClose} style={{ userSelect: "none",backdropFilter: "blur(3px)",height:'70rem'}} size="lg"  >
                    <Modal.Header  closeButton style={{padding:"2.5rem"}}>
                        <Modal.Title style={{ width: "100%", fontWeight: "bolder" }}>
                          <div >{item.Bookname}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body  >
                        <div style={{fontFamily: 'Nunito-Bold',fontWeight: '600',width:"100%",float:"left",fontSize: '1.5rem',margin: "2rem 0rem 2rem 2rem"}}><u>Book Summary : </u></div>
                        <div className="booklist_body_modal">
                                {item.summary}
                        </div>
                        <div style={{display: "flex",flexDirection: "row-reverse"}}>
                            <button  className="buy_button_m" onClick={() =>window.open (item.url)}>Buy Book</button>
                        </div>
                        
                    </Modal.Body>
                </Modal> :''}
                </>
            )
        })

    return(
        <>
            <div className="booksPage" >
            <FontAwesomeIcon icon={faArrowLeft} onClick={() =>history.push('/ish')} style={{cursor:"pointer"}} />
                <span style={{fontSize:'2rem',fontWeight:'800', marginLeft:"1rem"}}>Books</span>
                <div className="book_list">
                    {content_books}
                </div>
            </div>
        </>
    )
}