import { Redirect, Route, BrowserRouter as Router} from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import NewsHome from './pages/NewsHomePage';
import { StrategyTrackerPage } from './pages/StrategyTrackerPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FeedsenseModules } from './constants/FeedsenseModules';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { ArticlesByKeyword } from './pages/ArticlesByKeywordPage';
import { NewsArticle } from './pages/NewsArticlePage';
import { PortalHomePage } from './pages/PortalLoginPage';
import { HistoricalPerformancePage } from './pages/HistoricalPerformancePage'
import { userContext } from './Context/userContext';
import { getTokenFromStorage, getUserObject } from './Api/Service/auth.service';
import { PortfolioAnalyticsPage } from './PortfolioAnalytics/components/PortfolioAnalyticsPage';
import { ProfilePage } from './pages/ProfileSection';
import { UserPrivilegeService } from './util/UserPrivilegeService';
import { Privacypolicy } from './privacy-policy';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { uniqueArray } from './util/utils';
import "./theme/Switch_theme.scss"
import "./App.css"
import { get_Cookie } from './Cookies/get_cookie';
import { LeftNavigation } from './components/PortalHome/LeftNavigation';
import { TopBar } from './components/PortalHome/TopBar';
import data from "./data.json"
import { BackOfficePage } from './pages/BackOfficePage';
import { HomePage } from './Ish/homepage';
import { profile } from 'console';
import { BlogPage } from './Ish/Blogs';
import { BooksPage } from './Ish/BooksPage';
import { PicturesPage } from './Ish/PicturesPage';
import { FolderList } from './Ish/VideoPlayer/Folderlist';
import { PlayVideo } from './Ish/VideoPlayer/PlayVideo';
import { VideoList } from './Ish/VideoPlayer/VideoList';


let path:any = window.location.href.split('?')[1];
let news:any = window.location.pathname.split('/')[1];
let pathname:any = window.location.pathname;
let subPathName:any = window.location.pathname.split('/')[2];


function PrivateRoute({ children, ...rest }: any) {
  
  return (
    <Route {...rest} render={() => {
      let userObj = getUserObject();
      return (userObj.isLoggedIn === true && UserPrivilegeService.hasAccessToComponent(uniqueArray(userObj.privileges), rest.module)) ||
      (userObj.isLoggedIn === true )
        ? children
        : <Redirect to='/portal' />
    }} />
  )
}
const defaultoption={
  loop:true,
  autoplay:true,
  animationData:data,
  redererSettings:{
      preserveAspectratio:"xMidYMid slice",
  },
};

const App = () => {


  let location: any = window.location.pathname.split("/");

  let userObj = getUserObject();
  const [lastPong, setLastPong] = useState<any>([]);
  const [para, setPara] = useState<any>(window.location.pathname.split("/"))


  let new_theme = get_Cookie('Selected_theme')

  let default_theme = new_theme == undefined ? 'dark' : new_theme

  const [theme, setTheme] = useState(default_theme);

  const token: any = getTokenFromStorage()

  let user_id = get_Cookie('user_id')

console.log(userObj)

  return (
    <userContext.Provider value={{ user: getUserObject() }}>
      <>
          <Router>
            <IonRouterOutlet >
                  <Route exact path="/ish"  >
                  <HomePage  />
                </Route>
                <Route exact path="/ish/books" >
                  <BooksPage  />
                </Route>
                <Route exact path="/ish/pictures" >
                  <PicturesPage  />
                </Route>
                <Route exact path="/ish/blogs" >
                  <BlogPage  />
                </Route>
                <Route exact path="/ish/videos" >
                  <FolderList/>
                </Route>
                <Route exact path="/ish/videos/:folder" >
                  <VideoList  />
                </Route>
                <Route exact path="/ish/videos/:folder/:play" >
                  <PlayVideo userObj = {userObj} />
                </Route>
            </IonRouterOutlet>
          </Router>
      </>
    </userContext.Provider>
  )
};

export default App;


