import React, {  useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { Modal} from "react-bootstrap";
import { remove_Cookie } from "../Cookies/remove_cookie";


interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = ({}) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let history = useHistory();
  remove_Cookie('video_running')
  localStorage.setItem('Selected_langauge','english')

  const handleMenuClick = (menu: any) => {
      history.push('ish/' + menu.toLowerCase())

  }


  let menuitems = ['Videos', 'Books', 'Pictures', 'Blogs']
  // let menuitems = ['Videos', 'Books',  'Blogs']


  const subitems = menuitems.map((ab: any, index: any) => {
    return (
      <>
        <div key={index} className="grid-row-menu">
          <div className="grid-item-menu" onClick={() => handleMenuClick(ab)}>
            <img className="Homepage_logs" alt='' src={'./assets/ishIcons/' + ab + '_white.png'}></img>
            <div style={{ display: 'flex', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', fontFamily: 'Nunito-Bold', fontSize: '2rem' }} >{ab}</div>
          </div>
        </div>

      </>
    )
  })



  return (
    <>
        <div className="background_image" >
          <div >
              <img style={{height:"37rem", width:"100%"}} src={'./assets/icon/ishBanner.jpeg'}></img>
          </div>
          {/* div className="about_ish">
              <img style={{height: '6rem',width: '6rem',padding:'1rem'}} src={'./assets/ishIcons/ish_logo.png'}></img>
              Indian Spiritual Heritage
          </div> */}
          <div className="about_ish" >
                <p style={{ fontFamily: 'Nunito-Bold', fontSize: '2rem', margin: '3rem 0rem 0rem 3rem', textAlign: 'justify', color: 'black'}}>
                  The spiritual heritage of India is a brief history of the philosophy of Bharat. Our Motherland has gone through many trials and tribulations but has always intertwined philosophy with religion. In an effort to foster the Sri Ramakrishna led revival of the ancient Vedic movement, this website brings together several spiritual magnificence that includes the Vedas, the Upanishads, the Gita, the Ramayana, the Mahabharata, the Purans etc. Sri Samarpanananda Maharaj, a distinguished monk, associated with the Belur math and Ramakrishna Mission Vivekananda Education and Research Institute (University), has entered into ancient sacred treasures and offers wisdom to the modern spiritual seekers, through this website.
                  </p>
          </div>

          <div className="menulist" >
            {subitems}
          </div>
          <span style={{ fontFamily: 'Nunito-Bold', display: 'flex', justifyContent: 'center', fontSize: '1.55rem', color: 'black' }}>Visit us at</span>
          <span style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <img onClick={() => window.open('https://www.youtube.com/@IndianSpiritualHeritage')} style={{ width: '4rem',  borderRadius: '75%', height: '4rem', margin: '0.8%', cursor:'pointer' }} alt='' src={'./assets/ishIcons/Youtube_logo.png'}></img>
            <img onClick={() => window.open('https://www.facebook.com/people/Indian-Spiritual-Heritage/100063798043644/')} style={{ width: '4rem',height:'4rem',margin:'0.8%', cursor:'pointer' }} alt='' src={'./assets/ishIcons/Facebook-logo.png'}></img>
            <img onClick={() => window.open('https://twitter.com/indianspiritua1')} style={{ width: '4rem',height:'4rem',margin:'0.8%', cursor:'pointer' }} alt='' src={'./assets/ishIcons/Twitter_logo.png'}></img>
          </span>
          <div className="bottom-ribbon">
            <div onClick={handleShow} style={{ fontFamily: 'Nunito-Bold', display: 'flex', justifyContent: 'center', fontSize: '3rem', color: 'black',marginTop:'1%',cursor:'pointer' }}><u>Privacy policy</u></div>
            <Modal show={show} className="privacy_policy_modal" onHide={handleClose} style={{ userSelect: "none" }} size="xl" >
              <Modal.Header closeButton >
                <Modal.Title style={{ fontWeight: "bolder",  width: "100%", fontSize:"2.5rem"}}>
                  <img style={{ height: '5rem', width: '6rem', margin: '3rem' }} alt = '' src="assets/ishIcons/ish_logo.png" />
                  <span >ISH Privacy Statement</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div style={{ fontFamily: "Nunito-Bold", margin: '3%', fontSize: '1.5rem', textAlign: 'justify' }}>
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Privacy of Information  </span>
                  ISH application is owned and operated by Indian Spiritual Heritage through mobile phone devices or handheld devices. Any Personal Information provided by you or any personal information collected online via the ISH application is important to us and the same is protected with the utmost care by us. Indian Spiritual Heritage does not guarantee the protection of any information including but not limited to the Personal Information collected offline or through any other source and/or through any other third-party websites for which a link is available on the Indian Spiritual Heritage website for the purpose of either advertisement or for the purpose of other information for interested persons. These third-party websites are not under Indian Spiritual Heritage’s control and therefore any Personal Information provided on such websites is not captured at Indian Spiritual Heritage’s end and therefore no protection is available for Personal Information provided by you on such third-party websites. We are not responsible and/or liable for the disclosure of your Personal Information through such third-party websites. You are therefore requested to verify the privacy policies on such third-party websites before proceeding.
                </div>
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}} >Personal Information  </span>
                  "Personal Information" shall mean and include all such information whereby you can be identified as a particular individual by your name, phone number, email address, or any additional set of information such as unique device identifiers like IMEI, MEID, IMSI, or ESN, Media Access Control “MAC” address, and Internet Protocol or “IP” address etc.
                </div>
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Collection and Use of your Personal Information  </span>
                  We are committed at all times to ensuring the privacy of your Personal Information. We may obtain your information through your mobile phone device, or handheld device which may include information that can be used to identify you as a particular individual. The said information will be used by us to improve your experience of the ISH application service and update you about the same.
                </div>
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Device Information  </span>
                  We collect information from or about the phones, or other devices where you install or access Indian Spiritual Heritage ISH services. We may associate the information we collect from your devices, which helps us to provide consistent Services on your devices. Here are some examples of the device information we collect: Attributes such as the operating system, hardware version, device settings, and device identifiers. We also collect and analyze the International Mobile Equipment Identity (IMEI), operating system platform, and version of your device. We may also collect technical information to help us identify your device for diagnostic purposes only. The personally identifiable information is used by us to resolve disputes; troubleshoot problems; help to provide a safe service; to prevent abuse of the ISH Application.
                </div>
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Cookies Information  </span>
                  When you visit the Indian Spiritual Heritage site, we may send one or more cookies - a small text file containing a string of alphanumeric characters - to your handheld device/ computer that uniquely identifies your browser. A session cookie is temporary and disappears after you close your browser. You can configure your web browser to refuse all cookies or to indicate when a cookie is being sent. However, the Site may not function properly if the ability to accept cookies is disabled.
                </div>

                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Indian Spiritual Heritage Commitment to Data Security  </span>
                  Indian Spiritual Heritage uses the best practice in the industry to preserve the integrity and security of your Personal Information. Indian Spiritual Heritage cannot, however, ensure or warrant the security of any information you transmit from your device or on the Indian Spiritual Heritage website for ISH services. Once we receive your information, Indian Spiritual Heritage makes reasonable efforts to ensure the security of Indian Spiritual Heritage systems. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of Indian Spiritual Heritage's physical, technical, or managerial safeguards. If we learn of a security breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice on Indian Spiritual Heritage Site or update through the ISH Application service if a security breach occurs.
                </div>
                  
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Who has access to the personal information?  </span>
                  Indian Spiritual Heritage is bound by our commitments to the protection of your privacy/Personal Information and therefore Indian Spiritual Heritage does not provide access to your Personal Information to any person or third party. Notwithstanding Indian Spiritual Heritage’s commitment to the protection of your Personal Information, we may share your Personal Information with Indian Spiritual Heritage solicitors, lawyers, auditors, or any law enforcement authority on the direction for investigation, resolution of any dispute between us, or such governmental and local authorities on-demand or request. In such event, we shall not be held responsible for any breach of your privacy protection provided under this Privacy Statement.
                </div>

                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>How can I manage information about myself?  </span>
                  We store data for as long as it is necessary to provide services to you, including those described above. Information associated with your account will be retained with us even after you have discontinued Indian Spiritual Heritage services and confidentiality will be maintained.
                </div>
                  
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Your Discretion  </span>
                  If you do not agree with Indian Spiritual Heritage Privacy Statement or Terms of Service, you can uninstall the ISH application. If you have any queries please contact us via email to <a style={{ fontStyle: "italic", color: "#3880ff" }}>admin@feedsense.ai</a> with any questions or comments about this Privacy Statement, your personal information, your consent, or your opt-in or opt-out choices will be protected as confidential.
                </div>
                  
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Your Consent  </span>
                  By using Indian Spiritual Heritage Site and/ or ISH Application services and by providing your information, you consent to the collection and use of the information you disclose for availing ISH Services in accordance with this Privacy Statement, including but not limited to your consent for sharing your information as per this Privacy Statement.Ish Application is Powered by FeedSense AI
                </div>
                
                <div>
                  <span className="privacy-policy-headers" style={{ fontWeight: "bolder"}}>Changes and updates of Privacy Statement  </span>
                  This Privacy Statement may be revised periodically and this will be reflected by the “Revision date" below. Please revisit this page to stay aware of any changes. Your continuous use of ISH Service constitutes your agreement to this Privacy Statement and any amendments therein.
                </div>
                  
                  <div style={{ fontWeight: "bolder", textAlign: "right"}}>Last Revised on  01 Apr 2024</div>

                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
    </>
  );
};


