import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { remove_Cookie } from "../Cookies/remove_cookie";
import { axiosIsh, ishUrl } from "../Api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";



interface PicturesPageProps {}

export const PicturesPage: React.FC<PicturesPageProps> = ({ }) => {
    const history = useHistory()

    const [wallpaper, setWallpaper] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [modalData, setModalData] = useState('');
    const [imgIndex,setImgIndex] = useState(0)

    // const importAll = (r: __WebpackModuleApi.RequireContext) => {
    //     let images: string[] = [];
    //     r.keys().map((item) => {
    //       images.push(item);
    //     });
    //     return images;
    //   };
    
    //   const images = importAll(require.context('./path/to/images', false, /\.(png|jpe?g|svg)$/));
    
    

    remove_Cookie('video_running')

    useEffect(() => {
        axiosIsh.post('/ish/wallpaper').then((v: any) => setWallpaper(v?.data)).catch((e) => console.error(e))
    }, [])


    const content_wallpaper = wallpaper?.map((data: any, index: any) => (
        <>
            <div className="grid-row-pictures">
                <div className="grid-item-pictures" key={index} onClick={() => { setModalData(data);setImgIndex(index); setShow(true) }}>
                    <img id="images" style={{ height: '100%', width: '100%' }} alt="" src={ishUrl + "ish/view_wallpaper?imgName=" + data} />
                </div>
            </div>
            {/* <Modal show={show} className="piclist_modal" onHide={handleClose} style={{ userSelect: "none", height: '70rem' }} size="lg" >
                <Modal.Body >
                    {wallpaper?.map((data: any, index: any) => (
                        <>{ data === modalData?
                            <div className="viewpicturesModel">
                                <img className="leftslide" id="images" alt="" src={"./assets/icon/leftArrow.png"} onClick={()=>setImgIndex(index-1)} />
                                <img className="wallpaper_items" id="images" alt="" src={ishUrl + "ish/view_wallpaper?imgName=" + modalData} />
                                <img className="rightslide" id="images" alt="" src={"./assets/icon/leftArrow.png"} onClick={()=>setImgIndex(index+1)} />
                            </div>:''
                        }
                            </>
                        ))}
                </Modal.Body>
            </Modal> */}
        </>
    ));


    return (
        <>
            <div className="background_image">
                <div style={{ display: 'flex' , padding: '2% 1% 3%'}}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={() =>history.push('/ish')} style={{cursor:"pointer", marginTop:"0.8rem", marginRight:"0.8rem"}} />
                    <span style={{ fontWeight: '800', fontFamily: 'Nunito-Bold', fontSize: '1.8rem', color: 'black' }}> Pictures</span>
                </div>
                <div className="book_list" > 
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_1.jpeg' />
                        </div>
                    </div>
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_2.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_3.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_4.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_5.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_6.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_7.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_8.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_9.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_10.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_11.jpeg' />
                        </div>
                    </div>                    
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%' , borderRadius:"1rem"}} alt="" src='./assets/images/wallpaper_12.jpeg' />
                        </div>
                    </div>                   
                    <div className="grid-row-pictures">
                        <div className="grid-item-pictures" key={1} >
                            <img id="images" style={{ height: '100%', width: '100%', borderRadius:"1rem" }} alt="" src='./assets/images/wallpaper_13.jpeg' />
                        </div>
                    </div> 

                </div>
            </div>
        </>
    )
}