import Axios from "axios";
// import { getUserObject } from "./Service/auth.service";s
let token:any = localStorage.getItem("token");
// 167.71.238.76
// const feedsenseApi:any = "http://10.10.12.29:4000/";
const feedsenseApi:any = "http://167.71.238.76:4000/";

const authUrl:any = "http://192.168.29.89:4002/";
export const ishUrl:any = "http://192.168.29.28:4000/"

// const feedsenseApi:any = "https://feedsenseapi.feedsense.ai/";
// const authUrl:any = "https://adminapi.feedsense.ai";
const newsUrl:any = "https://api.feedsense.ai/requesthandler/v1";

// export const axiosLocal5000Local = Axios.create({ baseURL: "https://feedsenseapi.feedsense.ai/" ,
//     headers: {bearer: token}});

export const axios = Axios.create({ baseURL: newsUrl , // News Api
    headers: {bearer: token}});
    
export const axiosAuthService = Axios.create({ baseURL: authUrl , // Auth Api
    headers: {bearer: token}});

export const axiosLocal5000Local = Axios.create({ baseURL: feedsenseApi ,
    headers: {bearer: token}});

export const axiosLocal5000 = Axios.create({ baseURL: feedsenseApi , // Pair Trading Api
    headers: {bearer: token}});  

export const axiosUSD = Axios.create({ baseURL: feedsenseApi , // Buy Sell Api
    headers: {bearer: token}});

export const axiosBuySell = Axios.create({ baseURL: feedsenseApi , // Buy Sell Api
    headers: {bearer: token}});

export const axiosOption = Axios.create({ baseURL: feedsenseApi , // Buy Sell Api
    headers: {bearer: token}});

export const axiosSFMultiday = Axios.create({ baseURL: feedsenseApi, // Stock Future Multiday Api
    headers: {bearer: token}});

export const axiosFXOption = Axios.create({baseURL:feedsenseApi, // fx-option Api
    headers:{bearer:token}})

export const axiosUsdMultiday = Axios.create({ baseURL: feedsenseApi, // Stock Future Multiday Api
    headers: {bearer: token}});

export const axiosESMultiday = Axios.create({ baseURL: feedsenseApi, // Equity Stock Multiday Api
    headers: {bearer: token}});

export const axiosPortfolio = Axios.create({ baseURL: feedsenseApi, // Portfolio Api
    headers: {bearer: token}});

export const axiosIsh = Axios.create({ baseURL: feedsenseApi, // Ish Api
    headers: {bearer: token}});