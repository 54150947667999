import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./videolist_style.css"
import { getFilesByFolder } from "../../Api/ishApi/ishapi";
import { ishUrl } from "../../Api/axios";


interface VideoListprops {}

export const VideoList: React.FC<VideoListprops> = ({}) => {

  const list = useParams()
  let param_folder:any = Object.values(list)
  var Profile_key = sessionStorage.getItem("Profile_key");
  const [folder, setFolder] = useState<any>(decodeURI(param_folder));
  const [videolist, setVideolist] = useState([])

  const history = useHistory();
  // remove_Cookie('video_running')

  let langauge = localStorage.getItem('Selected_langauge')

  useEffect(() => {
    
   get_video(folder,localStorage.getItem('Selected_langauge'))
   setFolder(decodeURI(folder))
  }, [folder])


  const get_video =async (folder:any,langauge:any)=>{
    
      let Video_names = await getFilesByFolder(langauge,folder)
 
      Video_names = Video_names.sort((a:any,b:any) => a.match(/\d+/g)[0] - b.match(/\d+/g)[0]) 
      setVideolist(Video_names)
      
  }

  const handleVideoSelection = async (folder:any,item:any )=>{

        history.push(`${folder}/${item}`)
  }

  const subItems = videolist.map((item: any, index: any) => (
    
    <>
      {
        <div className="grid-row-folder" key={index}>
          <div className="grid-item-folder" onClick={() => handleVideoSelection(folder,item) }>
            {/* <LazyLoadImage className="subitems-thubnails" placeholderSrc={'../assets/icon/placeholder.png'} */}
              <img src={ishUrl + "ish/image_viewer?langauge="+ langauge +'&folderName=' + folder + '&fileName=' + item.split('.mp4')[0] + '.jpg'} 
              style={{height:'80%',width:'100%',borderRadius: '10px'}}/>
              <div > {item.length > 60 ? ( <span>{item.split(".")[0].slice(0, 50)}...</span> ) : ( item.split(".")[0])}</div>
          </div>
        </div>
      }
    </>
  ));

  return (
    <>
        <div className="background_image" >
            <div className="folder_title">{folder}</div>
                <div className="subfolder-list">
                  {videolist.length >= 1 ? subItems
                    : <div style={{ padding: '1rem', display: 'flex' }}>
                      {/* <Bars height="50" width="80" color="#F5EAEA" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={true} /> */}
                    </div>
                  }
                </div>
        </div>
    </>
  );
};
