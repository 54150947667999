import { axiosIsh } from "../axios";


export function getVideosFolder(langauge: any) {
    return axiosIsh.post(`/ish/list`, {
        "langauge": langauge
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error in api", err))
}


export function getPictures() {
    return axiosIsh.post(`/ish/wallpaper`, {}).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error in api", err))
}

export function viewPictures(imgName:any) {
    return axiosIsh.post(`/ish/view_wallpaper`, {
        "imgName":imgName
    }).then((res) => {
        return res;
    }).catch((err) => console.log("error in api", err))
}

export function getFilesByFolder(langauge:any,folderName:any) {
    return axiosIsh.post(`/ish/videolist`, {
        "langauge":langauge,
        "folderName":folderName
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error in api", err))
}

export function getVideoDetails(title:any) {
    return axiosIsh.post(`/ish/get_video_details`, {
        "videoName":title,
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error in api", err))
}


export function getVideoComments(videoId:any) {
    return axiosIsh.post(`/ish/get_video_comments`, {
        "videoId":videoId
    }).then((res) => {
        
        return res?.data[0];
    }).catch((err) => console.log("error in api", err))
}


export function insertUpdateComment (videoId:any,comment:any){
    return axiosIsh.post(`/ish/insert_comment`, {
        "videoId":videoId,
        "comment":comment
    }).then((res) => {
        return res?.data;
    }).catch((err) => console.log("error in api", err))
}



