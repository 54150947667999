import axios from "axios";
import { useState } from "react";
import "./style.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Jsondata from "./bloglist.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
       

interface BlogListProps {}

export const BlogPage: React.FC<BlogListProps> = ({ }) => {
  const [blogs, setBlogs] = useState<any>([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modalData, setModalData] = useState<any>();
  const history = useHistory()


  // useEffect(() => {
  //   import_blogs();
  // },[]);

  let bloglist: any = [];

  const import_blogs = () => {
    axios.get("https://www.googleapis.com/blogger/v3/blogs/4207112051862117578/posts?key=AIzaSyCnN3CGqnLrxVsxKs5f2NYyO8aGA3GNltw")
      .then((response: any) => { console.log(response.data.items, "response"); setBlogs(response?.data?.items); bloglist.push(JSON.parse(response?.data?.items)); });
  };

  console.log(bloglist, "bloglist", blogs);

  function extractContent(s: any) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  let updated_blogData: any = [];

  { blogs[0] == Jsondata[0] ? updated_blogData.push(Jsondata) : blogs.length < 1 ? updated_blogData.push(Jsondata) : updated_blogData.push(blogs); }

  const get_blogs = updated_blogData[0].map((value: any, index: any) => {
    return (
      <>
        <div key={index} className="grid-row-books">
          <div className="grid-item-books">
            {/* <img src={value.author.image.url} className="blogs_img" alt="..."/> */}
            <div className="blogs_card_title">
              <div className="blogs_date">{moment(value?.published).format("DD MMM YYYY")}</div>
              <p style={{ display: "flex", alignItems: "center", justifyContent: "center", height: '50%' }}>{value?.title} </p>
              <div className="read_more" onClick={() => { setModalData(value.id); setShow(true); }}>
                {/* <span className="blogs_author">{value?.author?.displayName}</span> */}
                <u style={{ color: '#47a2e7' }}>Read more</u>
              </div>
            </div>
          </div>

        </div>

        {value.id === modalData ? (
          <Modal show={show} className="blogs_modal" onHide={handleClose} style={{ userSelect: "none", backdropFilter: "blur(3px)" }} size="lg" >
            <Modal.Header style={{ width: "100%", fontWeight: "bolder", display: 'flex',flexDirection: 'column-reverse' }} closeButton>
              <div style={{ width: '100%' }}>
                <div className="blog_card_date"> Date : {moment(value.updated).format("DD MMM YYYY")} </div>
                <div className="blogs_title_modal">{value?.title}</div>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: "100%" }}>
              <div className="body_modal">
                <p contentEditable="false">{extractContent(value.content)} </p>

              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
      </>
    );
  });

  return (
    <>
      {/* <TopBar/> */}
      <div
        className="background_image">
          
        <div style={{ fontSize: '2rem', fontWeight: '800', padding: '2rem' }}>
        <FontAwesomeIcon icon={faArrowLeft} onClick={() =>history.push('/ish')} style={{cursor:"pointer", marginTop:"0.8rem", marginRight:"0.8rem"}} />
          Blogs 
          </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {get_blogs}
        </div>
      </div>
    </>
  );
};
