import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { set_Cookie } from "../../Cookies/set_cookie";
import { getVideoComments, getFilesByFolder, getVideoDetails, insertUpdateComment } from "../../Api/ishApi/ishapi";
import { ishUrl } from "../../Api/axios";


interface PlayVideoprops {
  userObj: any
}

export const PlayVideo: React.FC<PlayVideoprops> = ({ userObj }) => {
  let Parameteres = useParams()


  let param_value: any = Object.values(Parameteres)[1]

  const [v_details, setVDetails] = useState<any>(decodeURI(param_value));
  const [dataT, setDatat] = useState([""]);
  const [v_date, setVdate] = useState('')
  const [commenttext, setCommentText] = useState('')
  const [videoId, setVideoId] = useState('')
  const [allcomments, setallComments] = useState([])
  const [userName, setUserName] = useState(userObj?.name)
  const [checkComment, setCheckComment] = useState(false)
  const [postComment, setPostComment] = useState(false)

  let folder = (Object.values(Parameteres)[0]);


  let langauge = localStorage?.getItem('Selected_langauge')


  useEffect(() => {
    handleVideoDetails(v_details)
    set_Cookie('video_running', 'yes')
    setCommentText('')
  }, [v_details])


  useEffect(() => {
    getVideo(folder, langauge)
  }, [folder, langauge])



  const getVideo = async (folder: any, langauge: any) => {
    let Video_names = await getFilesByFolder(langauge, folder)
    Video_names = Video_names.sort((a: any, b: any) => a.match(/\d+/g)[0] - b.match(/\d+/g)[0]);
    setDatat(Video_names)

  }

  const handleVideoDetails = async (item: any) => {

    let videoDetails: any = await getVideoDetails(item);
    setVideoId(videoDetails?.s_video_id)
    handleVideoClick(videoDetails);
    handleCommentclick(videoDetails?.s_video_id)

  };

  const handleCommentclick = async (v_id: any) => {

    if (v_id != undefined) {
      let all: any = await getVideoComments(v_id)
      if (all.length === 0) {
        setallComments(all)
      }
      else {
        setallComments(all.s_comments)
      }
    }
  }




  const handlePostComment = async (comment_text: any, v_id: any, action: any) => {
    setUserName(userObj?.name)
    if ((Object.keys(allcomments).includes(userObj?.email_id) === true) && action !== 'Update') {
      setCheckComment(true)
    }
    else {
      let comment_list: any = {}
      comment_list['date'] = moment().format('YYYY-MM-DD')
      comment_list['user_name'] = userName
      comment_list['comment'] = comment_text
      if (allcomments.length === 0) {
        let insert_comments: any = {}
        insert_comments[userObj?.email_id] = comment_list
        insertUpdateComment(v_id, JSON.stringify(insert_comments))
        insert_comments = JSON.stringify(insert_comments)
        setallComments(JSON.parse(insert_comments))

      }
      else {
        let updated_comments: any = allcomments
        updated_comments[userObj?.email_id] = comment_list
        updated_comments = JSON.stringify(updated_comments)
        setallComments(JSON.parse(updated_comments))
        insertUpdateComment(v_id, JSON.stringify(allcomments))
      }
      setPostComment(false)
      setCommentText('')
      setCheckComment(false)
    }

  }


  const handleVideoClick = async (item: any) => {

    await setVDetails(item.s_title);
    await setVdate(item.dt_date)
    handleCommentclick(item?.v_id)
    let v_name: any = (item.s_title).split(".")[0];

    let video_player_div =
      "<video className='video_frame'  preload='auto' resume=true id='video' width='100%' controls controlsList=nodownload style ='width: 100%;border-radius: 1rem;height: 70%' ></video> ";
    document.getElementsByClassName("frame")[0].innerHTML = video_player_div;

    var video: any = document.getElementById("video");
    var source: any = document.createElement("source");

    source.setAttribute("src", ishUrl +'ish/videoplayer?langauge=' + langauge + '&folderName=' + folder + '&fileName=' + v_name);
    source.setAttribute("type", "video/mp4");

    video.appendChild(source);

  };



  const commnets = (Object.entries(allcomments).sort((v1: any, v2: any) => (Date.parse(v2[1].date) - Date.parse(v1[1].date))));
  const sortedComments = Object.fromEntries(commnets)
  const comm = Object.keys(sortedComments).map((i: any, index: any) => (

    <div style={{ display: 'flex', flexDirection: 'row' }} key={index}>
      <img style={{ height: '10rem', width: '10rem', padding: '1.2rem', margin: '1rem' }} alt='' src={'../assets/ishIcons/account.png'}></img>
      <div className="Comment_section" >
        <div className="user_details_section">
          <div className="user_name">{i.split('@')[0]} </div>
        </div>
        <div className="comments">
          <div style={{ width: '100%', lineHeight: '2rem', fontFamily: 'Nunito-Regular', fontSize: '1.55rem', marginTop: '1rem', fontWeight: '300', color: 'black' }}>{sortedComments[i]['comment']}</div>
        </div>
        <div className="c_date">{moment(sortedComments[i]['date']).format("MMM Do, YYYY")}</div>
      </div>
    </div>
  ))


  let new_subitems = dataT.filter((word) => word !== v_details + '.mp4');

  const remaining_playlist = new_subitems.map((item: any, index: any) => {
    if (item !== v_details) {
      return (
        <div className="rp_subitems" key={index}>
          <div className="subitems-title" onClick={() => handleVideoDetails(item)}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <img className="rp-thubnails" id="images"
                src={ishUrl + "ish/image_viewer?langauge=" + langauge + "&folderName=" + folder + '&fileName=' + item.split('.mp4')[0] + '.jpg'} />
              <div style={{ margin: "1% 2% 2% 2%", fontFamily: "Nunito-Bold", width: "65%", fontSize: "1.5rem", color: 'black', lineHeight: '2rem' }}>
                {item.length > 100 ? < >{(item.split('.')[0].slice(0, 100))}...</  > : (item.split('.')[0])}</div>
            </div>
          </div>
        </div>)
    }
  });


  return (
    <>{v_date !== null || v_details !== null ?
      <div className="background_image videoPlayerPage" >
        <div className='pageCard1' >
          <div className="frame" style={{ zIndex: "1" }}></div>
          <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
            <div style={{ padding: "2%", margin: "1%", fontSize: "1.55rem", fontFamily: "Nunito-Bold", width: "98%", color: "black" }}>
              {v_details.split('.mp4')[0]}
            </div>
            <div className="expandable">
              <div >
                <div className="comment_box">
                  <span className="comment_title" > Post your views here :</span>
                  <div style={{ display: 'flex' }}>
                    <textarea className="comment_int" name="comment" autoComplete="off" minLength={1} maxLength={500} value={commenttext}
                      placeholder="write comment upto 500 characters" onChange={(e: any) => setCommentText(e.target.value)} >
                    </textarea>
                    {(commenttext).length > 0 ? <button onClick={() => {
                      userName !== undefined ?
                      handlePostComment(commenttext, videoId, 'Insert') : setPostComment(true)
                    }} className="post_button">Post</button>
                      : <button className="disable_button">Post</button>}

                  </div>
                </div>
                {checkComment === true ?
                  <div className="edit_div">
                    <span style={{ lineHeight: '1%', width: '36rem', fontSize: '2rem', fontWeight: '800', marginTop: '2rem', textAlign: 'center' }}> Already commented...!!  </span>
                    <span style={{ lineHeight: '1%', width: '36rem', fontSize: '1.5rem', fontWeight: '300', textAlign: 'center' }}>Want to update comment ?</span>
                    <span>
                      <button className="s_button" onClick={() => handlePostComment(commenttext, videoId, 'Update')}> Yes</button>
                      <button className="s_button" onClick={() => setCheckComment(false)}>Cancel</button>
                    </span>
                  </div>
                  : ''
                }
              </div>
              <div>
                {(Object.keys(allcomments).length) > 0 ?
                  <div>
                    <span className="comment_title1" >Comments :  </span>
                    <div className="comment_list">{comm}</div>
                  </div>
                  : <div className="no_comment">No Comments</div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="pageCard2 remaining-list">
          {remaining_playlist.length !== 1 ?
            <div>
              <p style={{ fontSize: '1.8rem', fontFamily: 'Nunito-Bold' }}>Suggestions :</p>
              {remaining_playlist.slice(0, 10)}</div>
            : <div style={{ padding: '4rem', display: 'flex' }}>
              {/* <Bars height="50" width="80" color="#F5EAEA" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={loder} /> */}
            </div>}
        </div>
      </div> :
      <div >
        <div style={{ padding: '4rem', display: 'flex', justifyContent: 'space-around' }}>
          {/* <Bars height="50" width="80" color="#F5EAEA" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={loder} /> */}
        </div>
      </div>
    }
    </>


  );
};


